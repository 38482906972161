// Apply class when element is visible
export default function whenVisible() {
	const whenVisible = document.querySelectorAll('[data-when-visible]');

	if (whenVisible) {
		/* Instantly display visible on pageload */
		Array.prototype.forEach.call(whenVisible, element => {
			if (element.getBoundingClientRect().top <= window.innerHeight) {
				element.classList.add(element.dataset.whenVisible);
			}
		});

		/* Reveal more while scrolled */
		window.addEventListener('scroll', () => {
			Array.prototype.forEach.call(whenVisible, element => {
				if (element.getBoundingClientRect().top < (window.innerHeight * 0.66)) {
					element.classList.add(element.dataset.whenVisible);
				}
			});
		});
		window.dispatchEvent(new Event('scroll'));
	}
}
